<template>
  <mdb-modal
    v-if="esPaquetes"
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarModal"
    @close="
      $emit('cerrar', false);
      limpiarCampos();
    "
  >
    <mdb-modal-header>
      <mdb-modal-title>
        {{ tituloModal }}
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body class="pt-0">
      <!-- Listado de paquetes -->
      <section
        v-if="paquetes.length"
        class="animated fadeIn"
      >
        <ul class="table" data-table="sticky">
          <li class="encabezado row">
            <div class="col-3 col-sm-2 col-lg-1">Fecha</div>
            <div class="col-4 col-md-3 d-none d-lg-block">Estatus</div>
            <div class="col pl-0">Detalles</div>
            <div class="col-4 col-md d-none d-sm-block">Receptor</div>
          </li>
          <li
            v-for="paquete in paquetes"
            :key="`paquete-${paquete.id}`"
            class="contenido normal row align-items-center"
          >
            <!-- Fecha recibido -->
            <div class="col-3 col-sm-2 col-lg-1">
              <span class="d-block">
                {{ formatearFecha(paquete.fecha.formatted).ddmm }}
              </span>
              {{ formatearFecha(paquete.fecha.formatted).yyyy }}
            </div>
            <!-- Estatus -->
            <div class="col-4 col-md-3 d-none d-lg-block">
              {{ PaqueteEstatus[paquete.estatus] }}
              <p class="mb-0">
                Envío:
                <span :class="{ 'text-muted font-italic': !paquete.envio }">
                  {{
                    paquete.envio
                      ? ConsolidadoTipoEnvio[paquete.envio]
                      : "Sin especificar"
                  }}
                </span>
              </p>
              <p class="mb-0">
                Tipo:
                <span :class="{ 'text-muted font-italic': !paquete.tipo }">
                  {{ paquete.tipo ? paquete.tipo : "Sin especificar" }}
                </span>
              </p>
            </div>
            <!-- Detalles -->
            <div class="col pl-0">
              <!-- Columna estatus -->
              <div class="d-lg-none mb-1">
                {{ PaqueteEstatus[paquete.estatus] }}
                <p class="mb-0">
                  Envío:
                  <span :class="{ 'text-muted font-italic': !paquete.envio }">
                    {{
                      paquete.envio
                        ? ConsolidadoTipoEnvio[paquete.envio]
                        : "Sin especificar"
                    }}
                  </span>
                </p>
                <p class="mb-0">
                  Tipo:
                  <span :class="{ 'text-muted font-italic': !paquete.tipo }">
                    {{ paquete.tipo ? paquete.tipo : "Sin especificar" }}
                  </span>
                </p>
              </div>
              Tracking:
              <span :class="{ 'text-muted font-italic': !paquete.tracking }">
                {{ paquete.tracking ? paquete.tracking : "Sin especificar" }}
              </span>
              <p class="mb-0">
                <span title="Peso por volumen">
                  vlbs:
                  {{ mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166) }}
                  |
                </span>
                <span title="Peso">lbs: {{ mostrarSoloDosDecimales(paquete.peso) }} |</span
                >
                <span title="Pies cúbicos">
                  ft<sup>3</sup>:
                  {{ mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728) }}
                </span>
              </p>
              <!-- Columna receptor -->
              <p class="mb-0 mt-1 d-sm-none">
                Receptor:
                <span :class="{ 'text-muted font-italic': !paquete.receptor }">
                  {{ paquete.receptor ? paquete.receptor : "Sin especificar" }}
                </span>
              </p>
              <p class="mb-0 d-sm-none">
                Instrucciones de envío:
                <!-- Dirección de envío: -->
                <span
                  v-if="
                    paquete.direccionDestino &&
                    paquete.direccionDestino.ciudad &&
                    paquete.direccionDestino.direccion
                  "
                >
                  {{ paquete.direccionDestino.ciudad.estado.nombre }},
                  {{ paquete.direccionDestino.ciudad.nombre }},
                  {{ paquete.direccionDestino.direccion }}
                </span>
                <span v-else class="text-muted font-italic"
                  >Sin especificar</span
                >
              </p>
              <span v-if="paquete.observacion">
                Observación: {{ paquete.observacion }}
              </span>
            </div>
            <!-- Receptor -->
            <div class="col-4 col-md d-none d-sm-block">
              Receptor:
              <span :class="{ 'text-muted font-italic': !paquete.receptor }">
                {{ paquete.receptor ? paquete.receptor : "Sin especificar" }}
              </span>
              <p class="mb-0">
                Instrucciones de envío:
                <!-- Dirección de envío: -->
                <span
                  v-if="
                    paquete.direccionDestino &&
                    paquete.direccionDestino.ciudad &&
                    paquete.direccionDestino.direccion
                  "
                >
                  {{ paquete.direccionDestino.ciudad.estado.nombre }},
                  {{ paquete.direccionDestino.ciudad.nombre }},
                  {{ paquete.direccionDestino.direccion }}
                </span>
                <span v-else class="text-muted font-italic"
                  >Sin especificar</span
                >
              </p>
            </div>
          </li>
        </ul>
      </section>
      <!-- Caso: No se encontraron paquetes \\ no cuenta con consolidado el paquete-->
      <div
        v-else
        class="text-center text-muted user-select-none mb-4"
      >
        <font-awesome-icon
          :icon="['fas', 'exclamation-triangle']"
          class="fa-5x mb-4"
        />
        <p class="h3-responsive text-center mb-0 mx-lg-5 px-lg-5">
          Es necesario especificar los paquetes a imprimir etiquetas
        </p>
      </div>
    </mdb-modal-body>
    <!-- Funcionalidad crear PDF -->
    <mdb-modal-footer
      v-if="paquetes.length"
      class="justify-content-around mt-3"
    >
      <mdb-btn
        color="primario"
        :icon="botonDeshabilitado ? 'circle-notch' : 'print'"
        :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
        class="px-3"
        :disabled="botonDeshabilitado"
        @click="imprimirEtiquetasPaquete"
      >
        Imprimir
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import gql from "graphql-tag";
import etiquetasGql from "@/graphql/etiquetas.gql";
import cambioPaqueteIdGql from "@/graphql/cambioPaqueteId.gql";
import { PaqueteEstatus, PaqueteTipos } from "@/constantes/paquetes.js";
import { ConsolidadoTipoEnvio } from "@/constantes/consolidados.js";
import { imagen, maritimo, aereo } from "@/logos/logos.js";
import {endpointPublicBackend} from "@/constantes/paquetes.js"
import { generarEtiquetas } from "@/funciones/formatosImpresion.js"

import {
  mostrarSoloDosDecimales,
  formatearFecha,
  getBase64ImageFromURL
} from "@/funciones/funciones.js";
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalFooter,
} from "mdbvue";

// BarCode
var JsBarcode = require('jsbarcode');
const { createCanvas } = require('canvas');
const Canvas = createCanvas(0, 0);

// PDFmake
const pdfMake = require("pdfmake/build/pdfmake.js");
const pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "ModalEtiquetas",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalFooter,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: false,
    },
    // Se encuentra en la view Paquete
    esPaquetes: {
      type: Boolean,
      required: false,
    },
    imprimirEtiqueta: {
      type: Boolean,
      default: false,
      required: false,
    },
    paquetes: {
      type: Array,
      required: true,
      default: function () {
        return []
      }
    },
  },
  data() {
    return {
      PaqueteTipos,
      PaqueteEstatus,
      ConsolidadoTipoEnvio,
      mostrarSoloDosDecimales,
      formatearFecha,
      botonDeshabilitado: false,
      consolidados: [],
      imagen,
      maritimo,
      aereo,
    };
  },
  watch: {
    mostrarModal: function () {
      if (!this.mostrarModal) {
        this.limpiarCampos();
        this.botonDeshabilitado = false;
      }
    },
    imprimirEtiqueta: function () {
      if(this.imprimirEtiqueta) {
        this.imprimirEtiquetasPaquete()
      }
    },
  },
  computed: {
    tituloModal() {
      return this.esPaquete
        ? "Generar etiqueta a paquete"
        : "Generar etiqueta a paquetes";
    },
  },
  methods: {
    async imprimirEtiquetasPaquete () {
      let etiquetaPaquetes = []
      const cantidadPaquetes = this.paquetes.length;
      const paquetes = JSON.parse(JSON.stringify(this.paquetes))

      for (let i = 0; i < cantidadPaquetes; i++) {
        let paquete = paquetes[i]

        // Estableciendo el logo en formato correcto
        let logoAgencia = paquete.agenciaDestino && paquete.agenciaDestino.logo && paquete.agenciaDestino.logo.url
          ? `${endpointPublicBackend}${paquete.agenciaDestino.logo.url}`
          : ''
        paquete.agenciaDestino
          ? ''
          : paquete.agenciaDestino = {}
        logoAgencia
          ? paquete.agenciaDestino.urlLogo = await getBase64ImageFromURL(logoAgencia)
          : Object.assign( paquete.agenciaDestino, {
            urlLogo: this.imagen
          })

        // Estableciendo valores de N° de caja y Fecha Entrada Consolidado
        let fechaEntradaConsolidado = 'Sin especificar'
        let numeroCaja = 'Sin especificar';

        if(paquete.cliente && paquete.cliente.id && paquete.consolidado && paquete.consolidado.id){
          const cambioConsolidadoAsignado = await this.obtenerCambioConsolidado(paquete.id)
          const consolidado = await this.obtenerPaquetesConsolidado(paquete.cliente.id, paquete.consolidado.id)
          if(consolidado){
            const totalCajas = consolidado.paquetes.length
            consolidado.paquetes.map((P, I) => P.id == paquete.id ? numeroCaja = (I + 1) + ' / ' + totalCajas : '')
          }
          if(cambioConsolidadoAsignado){
            fechaEntradaConsolidado = cambioConsolidadoAsignado[0].fechaHora.formatted.substring(0, 10)
          }
        }
        paquete.numeroCaja = numeroCaja
        paquete.fechaEntradaConsolidado = fechaEntradaConsolidado

        // Definiendo medidas
        paquete.dimensiones = mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166)
        paquete.piesCubicos = mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728)

        // Estableciendo la dirección url de las políticas
        const politicaEnvio = paquete.agenciaDestino && paquete.agenciaDestino.enlace
          ? paquete.agenciaDestino.enlace
          : ''
        paquete.politicaEnvio = politicaEnvio

        // Definiendo símbolo de envío
        paquete.envioSimbolo = paquete.envio && paquete.envio.length
          ? paquete.envio === 'Aereo'
            ? this.aereo
            : this.maritimo
          : 'Sin especificar'

        // Obtener código de barras del paqueteId (numeroHR)
        JsBarcode(Canvas, paquete.numeroHR);
        paquete.codigoBarra = Canvas.toDataURL()

        etiquetaPaquetes.push(paquete);
      }

      const documento = generarEtiquetas(etiquetaPaquetes, this.imprimirEtiqueta)
      const info = {
        title: "Etiqueta de paquetes",
        author: "GLOBAL TRACKIT",
        keywords: "Etiqueta, paquetes",
      }

      this.generarPDF(info, documento)

      if(this.imprimirEtiqueta) {
        this.limpiarCampos()
        this.$emit('reiniciar-imprimir-Etiqueta', false)
      }
    },

    // Genera archivos PDF
    generarPDF (info, contenido) {
      const documento = {
        info: info,
        content: contenido,
        defaultStyle: {
          columnGap: 10,
          fontSize: 11,
        },
        styles: {
          header1: {
            fontSize: 24,
            bold: true,
            characterSpacing: 2,
          },
          header2: {
            fontSize: 18,
            bold: true,
          },
          header3: {
            fontSize: 16,
          },
          header4: {
            fontSize: 14,
          },
          header5: {
            fontSize: 12,
            bold: true,
          },
          header6: {
            fontSize: 11,
            bold: true,
          },
          tableHeader: {
            fontSize: 11,
            alignment: 'center',
            bold: true,
          },
          etiquetaFont1: {
            fontSize: 9,
          },
          small: {
            fontSize: 8,
          }
        },
        pageSize: "A4",
        pageOrientation: "portrait",
      };
      pdfMake.createPdf(documento).open();
    },
    async obtenerPaquetesConsolidado(idCliente, idConsolidado) {
      // Obtiene ids de paquetes ordenados asc en consolidado de un cliente en especifico 
      try{
        const filter = {
          id: idConsolidado,
        };
        const variablesQuery = {
          filter,
          idCliente,
          orderBy: ['fecha_asc'],
        };
        const res = await this.$apollo
          .query({
            query: gql`
              ${etiquetasGql}
            `,
            variables: variablesQuery,
            update: (data) => data.Consolidado,
            fetchPolicy: "no-cache",
          })
        this.botonDeshabilitado = false;
        return res.data.Consolidado[0];
      } catch {
        this.botonDeshabilitado = false;
        this.alertaMensaje(
          "Ha ocurrido un error inesperado, por favor intenta de nuevo",
          "error"
        );
      }
    },
    async obtenerCambioConsolidado(paqueteId) {
      // Obtiene cambio en el cual se asignó el consolidado actual al paquete
      try{
        const variablesQuery = {
          paqueteId,
          orderBy: ['fechaHora_desc'] 
        };
        const res = await this.$apollo
          .query({
            query: gql`
              ${cambioPaqueteIdGql}
            `,
            variables: variablesQuery,
            update: (data) => data.Paquete[0],
            fetchPolicy: "network-only",
          })
        this.botonDeshabilitado = false;
        // Ordenar los cambios asc
        const cambios = JSON.parse(JSON.stringify(res.data.Paquete[0].cambios))
        return cambios.sort((a, b) => new Date(b.fechaHora.formatted) - new Date(a.fechaHora.formatted))
      } catch {
        this.botonDeshabilitado = false;
        this.alertaMensaje(
          "Ha ocurrido un error inesperado, por favor intenta de nuevo",
          "error"
        );
      }
    },
    alertaMensaje(contenido, tipo) {
      this.$emit("alertaMensaje", {
        contenido,
        tipo,
      });
    },
    limpiarCampos() {
      this.botonDeshabilitado = false;
      this.consolidados = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.campo {
  margin-bottom: 1.1rem;
}
</style>
