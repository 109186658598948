<template>
  <!-- Modal Seleccionar Dirección -->
  <mdb-modal
    :show="mostrarModal"
    centered
    elegant
    scrollable
    @close="limpiarCampos(); $emit('close', false);"
  >
    <mdb-modal-header>
      <mdb-modal-title>Asignar a consolidado</mdb-modal-title>
    </mdb-modal-header>
      <mdb-modal-body>
      <p
        v-if="consolidados && !consolidados.length"
        class="h4-responsive text-muted text-center user-select-none mb-4"
      >
        No hay contenedores cargando
      </p>
      <div v-else>
        <div class="md-form md-outline outline-select my-2">
          <select
            id="consolidado-select"
            v-model="consolidadoId"
            class="custom-select"
          >
            <option class="d-none" disabled value="">Seleccione</option>
            <option
              v-for="consolidado in consolidados"
              :value="consolidado.id"
              :key="consolidado.id"
            >
              Guía consolidada: {{ consolidado.master ? consolidado.master : 'Sin especificar'}}
            </option>
          </select>
          <label
            for="consolidado-select"
            :class="consolidadoId ? 'label-active' : 'label-inactive'"
          >
            Contenedor
          </label>
        </div>
        <h5 class="text-center mt-2">
          {{ paquetes.length > 1
            ? "Los paquetes seleccionados"
            : "El paquete seleccionado"
          }}
        </h5>
        <!-- Resultado de computed que evalua los paquetes para asignar un consolidado -->
        <p class="mb-2">
          <font-awesome-icon
            :icon="textoValidadorDePaquetesAConsolidado.agenciaDestino ? 'check-circle' : 'times-circle'"
            :class="textoValidadorDePaquetesAConsolidado.agenciaDestino ? 'texto-exitoso' : 'texto-peligro'"
          />
          {{ textoValidadorDePaquetesAConsolidado.agenciaDestino ? "Si" : "No" }}
          cuenta con una agencia destino
        </p>
        <p class="mb-2">
          <font-awesome-icon
            :icon="textoValidadorDePaquetesAConsolidado.cliente ? 'check-circle' : 'times-circle'"
            :class="textoValidadorDePaquetesAConsolidado.cliente ? 'texto-exitoso' : 'texto-peligro'"
          />
          {{ textoValidadorDePaquetesAConsolidado.cliente ? "Si" : "No" }}
          tiene cliente asignado
        </p>
        <p class="mb-2">
          <font-awesome-icon
            :icon="textoValidadorDePaquetesAConsolidado.pagado ? 'check-circle' : 'times-circle'"
            :class="textoValidadorDePaquetesAConsolidado.pagado ? 'texto-exitoso' : 'texto-peligro'"
          />
          {{ textoValidadorDePaquetesAConsolidado.pagado ? "Si" : "No" }}
          se encuentra pagado o tiene crédito
        </p>
        <p class="mb-2">
          <font-awesome-icon
            :icon="textoValidadorDePaquetesAConsolidado.direccionDestino ? 'check-circle' : 'times-circle'"
            :class="textoValidadorDePaquetesAConsolidado.direccionDestino ? 'texto-exitoso' : 'texto-peligro'"
          />
          {{ textoValidadorDePaquetesAConsolidado.direccionDestino ? "Si" : "No" }}
          cuenta con dirección destino
        </p>
        <div
          :class="['text-center',
            {'cursor-not-allowed': botonDeshabilitado
              || !textoValidadorDePaquetesAConsolidado.activarBoton}
          ]"
          :title="!textoValidadorDePaquetesAConsolidado.activarBoton
            ? 'El paquete debe cumplir con todos los requisitos solicitados' : ''"
        >
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado && consolidadoId
              ? 'circle-notch' : 'check'"
            :icon-class="botonDeshabilitado && consolidadoId ? 'fa-spin' : ''"
            :disabled="botonDeshabilitado || !textoValidadorDePaquetesAConsolidado.activarBoton"
            class="mt-4"
            @click="paqueteAsignarConsolidado()"
          >
            Asignar paquete{{ paquetes.length > 1 ? "s" : "" }}
          </mdb-btn>
        </div>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import gql from "graphql-tag";
import paqueteAsignarConsolidadoGql from "@/graphql/paqueteAsignarConsolidado.gql"
import consolidadosGql from "@/graphql/consolidadosPorEstatus.gql";
import paquetesGql from "@/graphql/paquetes.gql";
import paqueteGql from "@/graphql/paquete.gql";
import { leerPersonaNombre } from "@/utils/datosToken.js"
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";

export default {
  name: 'ModalSeleccionarConsolidado',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    esRutaHija: {
      type: Boolean,
      required: false,
    },
    // offsetActual: {
    //   type: Number,
    //   required: false,
    // },
    // paginaLongitud: {
    //   type: Number,
    //   required: false,
    // },
    variablesUpdateQueryViewPaquetes: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    paquetesId: {
      type: Array,
      required: true,
    },
    paquetes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      botonDeshabilitado: false,
      consolidadoId: '',
      consolidadoArribo: false,
    };
  },
  computed: {
    textoValidadorDePaquetesAConsolidado() {
      if(this.mostrarModal){
        const validarValores = {
          agenciaDestino: true,
          cliente: true,
          pagado: true,
          direccionDestino: true,
          activarBoton: true
        }
        this.paquetes.forEach((p) => {
          if(!p.agenciaDestino || (p.agenciaDestino && !p.agenciaDestino.id)){
            validarValores.agenciaDestino = false
          }
          if(!p.cliente){
            validarValores.cliente = false
          }
          // Se establece falso si no se ha pagado y no tiene cliente
          // o si no ha pagado, tiene cliente y no tiene credito
          if((!p.pagado && !p.cliente) || (!p.pagado && p.cliente && !p.cliente.credito)){
            validarValores.pagado = false
          }
          if(!p.direccionDestino){
            validarValores.direccionDestino = false
          }
          if(p.consolidado && (p.consolidado.estatus !== "Cargando"
            && p.consolidado.estatus !== "NavieraAerolinea")){
            // Evalua si un paquete seleccionado cuenta con un consolidado que arribó
            this.consolidadoArribo = true
          }
        })
        // Se evalua si se activará el botón de asignar consolidado
        if (Object.values(validarValores).includes(false)) {
            validarValores.activarBoton = false;
        }
        return validarValores
      } else { return  ({}) }
    },
  },
  watch: {
    mostrarModal: function() {
      if(!this.mostrarModal) {
        this.limpiarCampos();
      }
    },
  },
  methods: {
    alertaMensaje(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    paqueteAsignarConsolidado() {
      this.botonDeshabilitado = true;
      if (this.consolidados.length > 1 && !this.consolidadoId) {
        this.botonDeshabilitado = false;
        return this.alertaMensaje('Selecciona un consolidado para continuar',
          'advertencia')
      }
      if (this.consolidadoArribo) {
        this.botonDeshabilitado = false;
        return this.alertaMensaje('Algún paquete seleccionado cuenta con un'+
          ' consolidado que se encuentra en tránsito o posterior.'+
          ' Por favor verifica tus datos', 'advertencia')
      }
      if(this.consolidados.length === 1){
        // En caso de solo existir un consolidado se selecciona automaticamente
        this.consolidadoId = this.consolidados[0].id
      }
      this.$apollo
        .mutate({
          mutation: gql`
            ${paqueteAsignarConsolidadoGql}
          `,
          variables: {
            paquetesId: this.paquetesId,
            consolidadoId: this.consolidadoId,
          },
          update: (store, {data: {paqueteAsignarConsolidado}}) => {
             const msg = paqueteAsignarConsolidado.codigo;
            switch (msg) {
              case "Correcto":
                break;
              default:
                this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo', 'error')
                this.botonDeshabilitado = false;
                return;
            }
            const consolidadoSeleccionado = this.consolidados.filter((e) => {
              return this.consolidadoId.includes(e.id);
            });
            // Si se esta en ruta hija se actualiza una query diferente
            if(this.esRutaHija){
              const data = store.readQuery({
                query: paqueteGql,
                variables: {
                  id: this.$route.params.paqueteId
                },
              });

              data.Paquete[0].consolidado = JSON.parse(JSON.stringify(consolidadoSeleccionado[0]))
              data.Paquete[0].estatus = 'Consolidado'
              consolidadoSeleccionado[0].tipo
                ? data.Paquete[0].envio = consolidadoSeleccionado[0].tipo
                : ''
              // Agregar al caché los cambios
              data.Paquete[0].cambios.unshift({
                autor: leerPersonaNombre(),
                descripcion: "Consolidado",
                cliente: this.paquetes[0].cliente
                  ? this.paquetes[0].cliente.nombre
                  : '',
                defectuoso: this.paquetes[0].defectuoso,
                nuevoEstatus: this.paquetes[0].estatus,
                pagado: this.paquetes[0].pagado,
                fechaHora: {
                  formatted: new Date().toISOString(),
                __typename: "_Neo4jDateTime"
                },
                __typename: "Cambio"
              })

              store.writeQuery({
                query: paqueteGql,
                variables: {
                  id: this.$route.params.paqueteId
                },
                data,
              });
            } else {
                // cuando se opera desde la view de paquetes
                const data = store.readQuery({
                    query: paquetesGql,
                    variables: this.variablesUpdateQueryViewPaquetes,
                });

                this.paquetesId.forEach(i => {
                  data.Paquete.map((p) => {
                    let paquete = p
                    // Actualizando datos del consolidado
                    if(p.id === i){
                      paquete.estatus = 'Consolidado'
                      paquete.consolidado = JSON.parse(JSON.stringify(consolidadoSeleccionado[0]))
                    }
                    return (paquete)
                  })
                });

                store.writeQuery({
                  query: paquetesGql,
                  variables: this.variablesUpdateQueryViewPaquetes,
                  data,
                });
            }

            this.botonDeshabilitado = false;
            this.alertaMensaje('Paquete asignado al contenedor correctamente',
                'correcto')
            this.$emit('close', false);
          },
        })
        .catch(()=>{
          this.alertaMensaje('Ha ocurrido un error inesperado.'
            +' Por favor intenta de nuevo', 'error')
          this.botonDeshabilitado = false
        })
    },
    limpiarCampos() {
      this.botonDeshabilitado = false;
      this.consolidadoId = '';
      this.consolidadoArribo = false;
    },
  },
   apollo: {
    consolidados() {
      return {
        query: gql`
          ${consolidadosGql}
        `,
        variables: {
          filter: {
            estatus: "Cargando",
          }
        },
        update: (data) => data.Consolidado,
        fetchPolicy: "cache-and-network",
      };
    },
  },
}

</script>

 <style lang="scss" scoped>

 </style>